/*** Custom CSS */

header.header-3 .top-header .header-cta ul li a i {
    color: #4e9652!important;
}

.logo {
    width: 174px!important;
    height: 40px!important;
}

.banner {
    background-image: url(../img/banner/banner-3.png);
}

.banner-slider__wrapper:after {
    background: none!important;
}

/*@media (max-width: 1399px) {*/
/*    .banner-slider__wrapper .slider-controls.slider-controls-2 {*/
/*        left: 50px!important;*/
/*}*/

/*.banner-slider__wrapper .slider-controls.slider-controls-2 {*/
/*    !*left: 1400px!important;*!*/
/*}*/

.banner-slider__wrapper .slider-controls .banner-slider-arrows .slick-arrow.slick-prev {
    color: #4e9652!important;
    border-color: #4e9652!important;
}

.banner-slider__wrapper .slider-controls .banner-slider-arrows .slick-arrow.slick-next {
    color: #4e9652!important;
    border-color: #4e9652!important;
}

.banner-slider__wrapper .slider-controls .banner-slider-arrows .slick-arrow.slick-prev:hover {
    color: #FFF!important;
    background: #4e9652!important;
}

.progress-bar {
    background-color: #4e9652!important;
}

.our-company__meida .horizental-bar {
    background: #1b2234!important;
}

.bg-primary {
    background-color: #4e9652!important;
}

.why-choose__content:not(.why-choose__content-home) {
    padding-left: 0!important;
}


.financial__item {
    background-image: url(../img/home/financial__item-bg.png)
}

.project-details-btn {
    background-image: url(../img/home/theme-btn-overly.png);
    background-color: #4e9652!important;
}

.our-portfolio-home:after {
     background-image: none!important;

}

.our-portfolio-home__item .content .left .post-author a {
    color: #4e9652!important;
}

.blog-news {
    background-image: url(../img/home-3/blog-new-bg.png);
}

/*.widget_categories ul li a {*/
/*    line-height: 16px!important;*/
/*}*/

.team-item.team-page .media img {
    width: 100%!important;
    height: auto!important;
}

.pagination-item {
    background-image: url(../img/project-details/pagination-item-1.png);
}

.footer-top__box-wrapper {
    background-image: url(../img/footer/footer-box-bg.png);
}

.page-banner__media:before {
    background: rgba(78, 150, 82, 0.5)!important;
}

/*.search_widget .search_box form input {*/
/*    border: 1px solid rgba(78, 150, 82, 0.3)!important;*/
/*}*/

img.search_icon {
    max-width: 10%!important;
    padding-right: 15px!important;
}

.search_widget .title {
    color: #0E1E36;
}

.search_widget .search_box form {
    height: 61px;
    display: flex;
    position: relative;
}

@media (min-width: 992px) {
    .search_widget {
        max-width: 550px;
        margin-left: auto;
    }
    .recent-publication-item img {
        width: auto!important;
    }

    .recent-publication-item-content {
        max-width: 350px!important;
    }
}

@media (max-width: 480px) {
    .search_widget .search_box form {
        height: 50px;
    }
}

.search_widget .search_box form input {
    width: 100%;
    color: #0E1E36;
    font-size: 16px;
    line-height: 19px;
    border-radius: 2px;
    background: transparent;
    padding: 21px 200px 21px 20px;
    border: 1px solid rgba(14, 30, 54, 0.3);
}
@media (max-width: 480px) {
    .search_widget .search_box form input {
        font-size: 14px;
        padding: 16px 148px 16px 15px;
    }
}

.search_widget .search_box form input::-moz-placeholder {
    color: #0E1E36;
}
.search_widget .search_box form input:-ms-input-placeholder {
    color: #0E1E36;
}
.search_widget .search_box form input::placeholder {
    color: #0E1E36;
}
.search_widget .search_box form #searchButton {
    margin-top: 6px;
    margin-bottom: 6px;
    border: 0;
    right: 9px;
    color: #FFF;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    position: absolute;
    font-family: "Poppins", sans-serif;
    border-radius: 2px;
    display: inline-block;
    box-sizing: border-box;
    padding: 9.5px 10px;
    top: calc(50% - 30.5px);
    background-color: #0E1E36;
    transition: all 0.4s ease-in-out;
    height: 80%;
}

.search_widget .search_box form #resetButton {
    margin-top: 6px;
    margin-bottom: 6px;
    padding-right: 15px;
    padding-left: 15px;
    right: 69px;
    color: #FFF;
    background-color: #4e9652;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    border-radius: 2px;
    top: calc(50% - 30.5px);
    display: inline!important;
    box-sizing: unset!important;
    position: absolute;
    height: 80%;
    text-align: center;

}

@media (max-width: 480px) {
    .search_widget .search_box form #searchButton {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 13px;
        line-height: 20px;
        padding: 6px 10px;
        top: calc(50% - 25px);
        right: 5px;
    }
    .search_widget .search_box form #resetButton {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 13px;
        line-height: 20px;
        padding: 0 10px;
        top: calc(50% - 25px);
        right: 65px;
    }
}

.search_widget .search_box form #searchButton:hover, .search_widget .search_box form #resetButton:hover {
    color: #0E1E36;
    background-color: #FF9C00;
}

.blog-title {
    min-height: 60px!important;
}

.our-project-details .media img {
    object-fit: fill!important;
}

.cta-banner__content {
    min-height: 320px!important;
}

/*.blog-item .blog-featured-thumb .media img {*/
/*    object-fit: fill!important;*/
/*}*/

.our-project__item img {
    object-fit: fill!important;
}

.our-project__item.gallery_photo img {
    object-fit: cover!important;
}

.slider-btn {
    background-color: #4e9652!important;
    color: #FFFFFF;
}

.video-item .video-item-thumb {
     height: 270px!important;
}

.video-item .media {
    height: auto!important;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*padding: 10px;*/
}

.lightbox-img{
    background-color: black;
}

.slider-single {
    padding-bottom: 160px!important;
    padding-top: 300px!important;
}

/*.slider-content {*/
/*    margin-top: -150px!important;*/
/*}*/



/*.blog-item .blog-featured-thumb .media img {*/
/*    width: 100%!important;*/
/*    height: auto!important;*/
/*}*/

    @media (max-width: 767px) {
    .page-banner__media:before {
        top: 0!important;
        height: 100%!important;
        left: -9999px!important;
        width: 9999px!important;
        transform: inherit!important;
        background-color: #0E1E36!important;
    }

        .placeholder-1 {
            margin-left: 40px!important;
        }

        .placeholder-2 {
            margin-left: 30px!important;
        }
}

@media (max-width: 389px) {

    .placeholder-1 {
        margin-left: 10px!important;
    }

    .placeholder-2 {
        margin-left: 70px!important;
    }
}

.footer-1 .newsletter_widget .newsletter_box form input {
    border: 1px solid rgba(78, 150, 82, 0.3)!important;
}

.progress-bar__wrapper .single-progress-bar .progress-bar .placeholder {
    background-image: url(../img/icon/comment-1.svg)!important;
}

.footer-1 .recent_post_widget .recent-post-list .single-recent-post .post-data {
    max-width: 450px!important;
}


.similar-work__item .media .no-photo {
    width: 100%!important;
    height: 40%!important;
    margin-top: 15%
}

.blog-item .blog-featured-thumb .media .no-photo {
    height: 65%!important;
    margin-top: 7%
}

.slider-btn-safari {
    background: #4e9652;
    padding: 15px 29px;
    color: #ffffff;
}

.slider-btn-safari:hover {
    color: #FFF;
    background-color: #FF9C00!important;
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.video-item-title {
    min-height: 80px!important;
}

.video-item-title a {
    font-size: 14px!important;
}

.video-item-desc {
    min-height: 140px!important;
}

.video-item-desc p {
    font-size: 14px!important;
}

.blog-video-item-title {
    min-height: 30px!important;
}

.video-item-desc {
    min-height: 160px!important;
}

@media (max-width: 991px) {
    .cta-banner__content-text .title {
        font-size: 24px!important;
        line-height: 34px!important;
    }
    .video-item .video-item-thumb {
        height: 220px!important;
    }
}

@media (max-width: 767px) {
    .cta-banner__content-text .title {
        font-size: 20px!important;
        line-height: 30px!important;
    }
}

@media (max-width: 575px) {
    .slider-single {
        padding-bottom: 40px!important;
        padding-top: 140px!important;
    }
}

@media (min-width: 1200px) {
    .why-choose__media {
        padding-left: 85px!important;
        padding-top: 120px!important;
    }
}

@media (max-width: 1200px) {
    .why-choose__media {
        padding: 85px 0!important;
    }
}

@media (min-width: 767px) and (max-width: 991px)  {
    .video-item-desc {
        min-height: 350px!important;
    }
}

.text-green {
    color: #4e9652;
}